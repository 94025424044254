<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Dar de baja</h1>
            <div class="info-card">
                <p>Lista de Ventas</p>
                <hr>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <small>Tipo de búsqueda</small>
                        <select name="" v-model="type_search" class="form-control">
                            <option value="code">Código</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="justify-content: space-between;">
                    <div class="col-md-4">
                        <p style="display: inline-block;">Mostrar</p>
                        <select 
                            class="select" 
                            v-model="limit_sales"
                            @change="loadSalesUsers()"
                        >
                            <option value="10">10</option>
                            <option value="100">100</option>
                            <option value="1000000">Todos</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Búsqueda..." v-model="search">
                            <div class="input-group-append">
                                <button type="submit" class="input-group-text"><span class="material-icons-sharp">search</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table" v-if="loaded_comm">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Comisión</th>
                            <th>Fecha</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sale, index) in sales" :key="index">
                            <td>{{sale.client_user_id || '-'}}</td>
                            <td>{{sale.client_user_name || 'Multinivel Café'}}</td>
                            <td>${{sale.commission}}</td>
                            <td>{{formatDate(sale.created_at)}}</td>
                            <td>
                                <button
                                @click="showConfirmDelete(sale.id)" class="btn btn-danger" 
                                style="display: inline-flex; align-items: center;">
                                    <span class="material-icons-sharp active" style="font-size: 1.2rem;">delete</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <div style="display: flex; justify-content: center; ">
                        <div class="lds-dual-ring"></div>
                    </div>
                </div>
            </div>
        </main>
        <Right/>
    </div>
</template>
<script>
import axios from 'axios';
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';

export default {
    name: '',
    data: function (){
        return {
            search: '',
            type_search: 'code',
            payment_money: '',
            commission: {
                user_id: '',
                user_name: '',
                user_id_card:  '',
                money: ''
            },
            loaded_comm: false,

            sales : [],
            limit_sales: 10
        }
    },
    components: {
        Aside,
        Right,
    },
    methods: {
        loadSalesUsers(search = {}){
            search.limit = this.limit_sales;
            let url = this.api_url+'/api/search/sales';
            axios.post(url, search, 
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.loaded_comm =  true;
                this.sales = res.data;
            }).catch(e=> console.log(e.response))
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            if(mes < 10){
                mes="0"+mes
            }
            if(dia < 10 ){
                dia="0"+dia
            }
            return ano+"/"+mes+"/"+dia;
        },
        showConfirmDelete(id) {
            this.$swal.fire({
                title: '¿Estás seguro que deseas eliminar este registro?',
                html: 'Una vez eliminado no podrás revertir esta acción',
                showDenyButton: true,
                confirmButtonText: '¡Si, elimínalo!',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = this.api_url+'/api/sale/delete/'+id;
                    axios.delete(url,
                    { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(() => {
                        this.loadSalesUsers({ client_id:this.search,  order: 'DESC', limit: 100 });
                        return this.launchAlert({type: 'success', title: 'Registro eliminado exitosamente'});
                    }).catch(e => console.log(e))
                }
            })
        },
        formatMoney(number){
            let money = new Intl.NumberFormat('em-IN').format(number);
            let split = money.split(',');
            let decimals = split[1];
            if(!decimals) decimals = '00';
            if (window.innerWidth <= 838) return [Math.trunc(number), '00']; 
            return [split[0], decimals];
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapMutations(['loadSessionUser']),
        ...mapActions("commission", ["searchPayments"]),
        ...mapActions("commission", ["paymentCommission"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        let session_interval = setInterval(() => {
            if(this.session_user.id) {
                this.loadSalesUsers();
                return clearInterval(session_interval);
            }
        }, 100);
    },
    computed: {
        ...mapState(['session_user']),
        ...mapState(['api_url']),
    },
    watch: {
        search: function () {
            this.loadSalesUsers({
                client_id:this.search, 
                order: 'DESC', 
            });
        }
    }
}
</script>
<style scoped>
    .select {
        width: auto;
        display: inline-block;
        margin-left: 0.5rem;
        padding: 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
</style>